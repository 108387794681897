<template>
    <div>
        <el-table v-if='viewer.length != 0'
        size="small"
        :data="viewer"
        height="calc(100vh - 265px)"
        style="width: 100%">
            <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50">
            </el-table-column>
            <el-table-column
                prop="applyName"
                label="申请组织"
                align="center">
            </el-table-column>
            <el-table-column
                prop="username"
                label="申请人"
                align="center">
            </el-table-column>
            <el-table-column
                prop="description"
                label="申请理由"
                align="center">
            </el-table-column>
             <el-table-column
                label="状态"
                align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.state == 0">待处理</el-tag>
                    <el-tag type="success" v-if="scope.row.state == 1">通过</el-tag>
                    <el-tag type="danger" v-if="scope.row.state == 2">拒绝</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                label="申请日期"
                align="center">
                <template slot-scope="scope">
                    {{scope.row.createDate | date}}
                </template>
            </el-table-column>
            <el-table-column
            label="操作"
            align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small" style="color: #67C23A;" v-if="scope.row.state == 0" @click="applyBtn(scope.row, 1)">通过</el-button>
                    <el-button type="text" size="small" style="color: #F56C6C;" v-if="scope.row.state == 0" @click="applyBtn(scope.row, 2)">拒绝</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="no-data-box" v-if='viewer.length == 0'>
            <img src="@/assets/no-file.svg" alt="">
            <span>暂无数据</span>
        </div>

        <el-pagination v-if='viewer.length != 0'
            class="pagebox"
            @current-change="handleCurrentChange"
            :current-page="formInline.pageNum"
            :page-size="formInline.pageSize"
            layout="total,  prev, pager, next, jumper"
            :total="total">
        </el-pagination>

        <el-dialog
        title="密集"
        :close-on-click-modal="false" 
        :visible.sync="dialogVisible"
        width="30%">
        <div>
            <el-select v-model="selectValue" size="small" placeholder="请选择密集">
                <el-option
                v-for="item in miji"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="saveBtnEvent">确 定</el-button>
        </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            viewerKey:'apply',
            formInline: {
                username: '',
                pageNum: 1,
                pageSize: 10
            },
            selectValue: '',
            rowInfo: {},
            dialogVisible: false
        }
    },
    computed:{
        viewer(){
            return this.$store.state.newOrg.views[this.viewerKey].list || []
        },
        total(){
            return this.$store.state.newOrg.views[this.viewerKey].paging.total;
        },
        miji(){
            return this.$store.state.dict.dicts.miji
        },
    },
    mounted(){
        this.getOrgApplyList();

        this.$ebus.$on('handleAuditSearchBtn', val=>{
            this.formInline.username = val.username;
            this.getOrgApplyList()
        })
    },
    methods: {
        // 重置
        resetForm() {
            this.formInline = {
                username: '',
                pageNum: 1,
                pageSize: 10
            }
            this.getOrgApplyList();
        },
        // 请求列表数据
        getOrgApplyList(){
            this.$store.dispatch('newOrg/getOrgApplyList',{
                reset: true,
                query: this.formInline
            })
        },
        // 分页
        handleCurrentChange(num) {
            this.formInline.pageNum = num;
            this.getOrgApplyList();
        },
        applyBtn(row, nums) {
            if(nums == 1) {
                this.dialogVisible = true;
                this.rowInfo = row;
            } else {
                this.$store.dispatch('newOrg/approveJoin',{
                    id: row.id,
                    state: nums
                }).then(state=>{
                    if(state.code==0){
                        this.$message.success('拒绝成功')
                        this.getOrgApplyList();
                    }else{
                        this.$message.error(state.message)
                    }
                }).catch(state=>{
                    this.$message.error(state.message)
                })
            }
        },
        saveBtnEvent(){
            this.$store.dispatch('newOrg/approveJoin',{
                id: this.rowInfo.id,
                miji: this.selectValue,
                state: 1
            }).then(state=>{
                if(state.code==0){
                    this.$message.success('审批成功')
                    this.getOrgApplyList();
                }else{
                    this.$message.error(state.message)
                }
            }).catch(state=>{
                this.$message.error(state.message)
            })
            this.dialogVisible = false;
        }
    },
    beforeDestroy() {
        this.$ebus.$off('handleAuditSearchBtn');
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-table {
    .el-table__header-wrapper {
        .has-gutter {
            tr th{
                border:none;
                background: #F2F2F2;
                color: #666;
            }
        }
    }
    .el-table__body-wrapper {
        tr td {
            border:none;
            padding: 12px 0;
        }
    }
    &::before {
        height: 0px;
    }
}
.pagebox {
    margin-top: 20px;
    text-align: left;
}
</style>